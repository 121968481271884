.fieldSpacing {
    > * + * {
        margin-top: theme('fields.spacing.default');
    }
}

.field {
    width: 4.5rem;
}

.dob_question {
    display: flex;
    gap: theme('fields.spacing.default');

    input {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            @apply appearance-none;
            margin-right: 0;
        }
    }

}

.age {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.75rem;
    padding: 0 theme('spacing.075');
    margin-left: auto;
    background: theme('colors.status.info.default');
    border-radius: theme('borderRadius.050');
    color: theme('colors.neutrals.white');
}
