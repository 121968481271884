.attention {
    padding: theme('spacing.150') theme('spacing.100');
    border-radius: theme('attention.borderRadius');

    > * + * {
        margin-top: theme('attention.spacing');
    }
}

.info {
    border: theme('attention.info.border');

    svg {
        fill: theme('attention.info.color');
    }
}

.success {
    border: theme('attention.success.border');

    svg {
        fill: theme('attention.success.color');
    }
}

.warning {
    border: theme('attention.warning.border');

    svg {
        fill: theme('attention.warning.color');
    }
}

.error {
    border: theme('attention.error.border');

    svg {
        fill: theme('attention.error.color');
    }
}
