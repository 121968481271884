.date {
    &::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url('/images/icons/calendar-light.svg') no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        border-width: thin;
    }
}
